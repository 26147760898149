<template>
  <Schema>
    <div v-if="!isActiveEditReport" class="w-full">
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span class="text-blue-500"> • Relatórios</span>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div>
          <h1 class="text-3xl text-gray-600">Relatórios</h1>
          <p class="text-gray-400 max-w-xs mt-1">
            Gere relatórios personalizados do sistema
          </p>
        </div>

        <button
          type="button"
          @click="onToggleCreateReport"
          class="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:brightness-90 transition-all"
        >
          <i class="ph ph-plus"></i>
          Adicionar um relatório
        </button>
      </div>

      <table class="w-full table-fixed mt-10">
        <thead>
          <tr>
            <td class="bg-blue-500 text-white py-2 text-center rounded-l-lg">
              ID
            </td>
            <td class="bg-blue-500 text-white py-2 text-center">Nome</td>
            <td class="bg-blue-500 text-white py-2 text-center rounded-r-lg">
              Ações
            </td>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in reports" :key="item.id">
            <td class="bg-gray-100 text-gray-500 py-2 text-center">
              {{ item.id }}
            </td>
            <td class="bg-gray-100 text-gray-500 py-2 text-center">
              {{ item.name }}
            </td>
            <td class="bg-gray-100 text-gray-500 py-2 text-center">
              <button
                type="button"
                @click="handleGetReport(item.id)"
                class="text-xl w-10 h-10 rounded-lg hover:bg-gray-500 hover:text-white transition-all inline-flex items-center justify-center"
              >
                <i class="ph ph-pencil-simple"></i>
              </button>

              <button
                type="button"
                @click="handleActiveReport(item.id)"
                class="text-xl w-10 h-10 rounded-lg hover:bg-gray-500 hover:text-white transition-all inline-flex items-center justify-center"
              >
                <i class="ph ph-play-circle"></i>
              </button>

              <button
                type="button"
                @click="handleDeleteReport(item.id)"
                class="text-xl w-10 h-10 rounded-lg hover:bg-gray-500 hover:text-white transition-all inline-flex items-center justify-center"
              >
                <i class="ph ph-trash-simple"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="w-full">
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <button
            type="button"
            @click="isActiveEditReport = !isActiveEditReport"
            class="hover:text-blue-500 transition-colors"
          >
            Relatório
          </button>
          <span class="text-blue-500">
            • {{ createdButton ? 'Criar' : 'Editar' }} relatório</span
          >
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div>
          <h1 class="text-3xl text-gray-600">
            {{ createdButton ? 'Criar' : 'Editar' }} relatório
          </h1>
          <p class="text-gray-400 max-w-xs mt-1">
            {{ createdButton ? 'Crie' : 'Edite' }} o relatório atual
          </p>
        </div>
      </div>

      <form class="w-full mt-14">
        <div class="flex items-center gap-2">
          <label class="text-sm text-gray-500 w-full">
            Nome do relatório

            <input
              type="text"
              v-model="nameReport"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
        <textarea
          v-model="queryString"
          class="border-2 border-gray-300 h-[300px] rounded-lg w-full text-md mt-2 p-4"
        />

        <button
          v-if="!createdButton"
          type="button"
          class="bg-blue-500 w-full text-white rounded-lg py-2 mt-4 hover:brightness-90 transition-all"
          @click="handleUpdateReport()"
        >
          Atualizar relatório
        </button>

        <button
          v-else
          type="button"
          class="bg-blue-500 w-full text-white rounded-lg py-2 mt-4 hover:brightness-90 transition-all"
          @click="handleCreateReport"
        >
          Criar relatório
        </button>
      </form>
    </div>
  </Schema>
</template>

<script>
import { useToast } from 'vue-toastification';
import Schema from '../../components/Schema/index.vue';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';

export default {
  name: 'Reports',

  components: {
    Schema,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      reports: [],
      isActiveEditReport: false,
      nameReport: '',
      queryString: '',
      reportId: '',
      createdButton: false,
    };
  },

  mounted() {
    this.handleGetAllReports();
  },

  methods: {
    handleGetAllReports() {
      const token = Cookies.get('token');

      api
        .get('/reports', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.reports = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleActiveReport(id) {
      const token = Cookies.get('token');

      api
        .get(`/reports/execute/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.toast.success('Query executada com sucesso!');
          window.location.href = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleGetReport(id) {
      const token = Cookies.get('token');

      api
        .get(`/reports/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.nameReport = res.data.name;
          this.queryString = res.data.queryString;
          this.reportId = res.data.id;
          this.isActiveEditReport = true;
          this.createdButton = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleUpdateReport() {
      const token = Cookies.get('token');

      api
        .patch(
          `/reports/${this.reportId}`,
          {
            name: this.nameReport,
            queryString: this.queryString,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((res) => {
          this.toast.success('Relatório atualizado com sucesso');
          this.isActiveEditReport = false;
          this.nameReport = '';
          this.queryString = '';
          this.handleGetAllReports();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleDeleteReport(id) {
      if (window.confirm('Você realmente quer excluir esse relatório?')) {
        const token = Cookies.get('token');

        api
          .delete(`/reports/${id}`, {
            headers: {
              token: token,
            },
          })
          .then((res) => {
            this.toast.success('Relatório excluído com sucesso');
            this.handleGetAllReports();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    onToggleCreateReport() {
      this.isActiveEditReport = true;
      this.createdButton = true;
      this.nameReport = '';
      this.queryString = '';
    },

    handleCreateReport() {
      const token = Cookies.get('token');

      api
        .post(
          '/reports',
          {
            deleted: false,
            name: this.nameReport,
            queryString: this.queryString,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((res) => {
          this.toast.success('Relatório criado com sucesso');
          this.isActiveEditReport = false;
          this.handleGetAllReports();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
