<template>
  <div
    class="bg-gray-50 p-4 rounded-lg border border-dashed border-gray-200 mb-5"
  >
    <div class="flex items-center gap-3 w-full select-none">
      <div class="mb-6 flex-1">
        <label class="block mb-2 text-sm text-gray-500"
          >Filtrar por unidade</label
        >
        <Dropdown
          v-model="filterUnit"
          :options="units"
          optionLabel="name"
          placeholder="Selecione uma unidade"
          class="w-full md:w-14rem"
          @change="handleGetLocalsByUnit"
          optionValue="id"
        />
      </div>

      <div class="mb-6 flex-1">
        <label class="block mb-2 text-sm text-gray-500"
          >Filtrar por local</label
        >
        <Dropdown
          v-model="filterLocal"
          :options="locals"
          optionLabel="name"
          placeholder="Selecione um local"
          class="w-full md:w-14rem"
          :disabled="locals.length === 0"
          optionValue="id"
          @change="handleGetRoomsByLocal"
        />
      </div>

      <div class="mb-6 flex-1">
        <label class="block mb-2 text-sm text-gray-500">Filtrar por sala</label>
        <Dropdown
          v-model="filterRoom"
          :options="rooms"
          optionLabel="name"
          placeholder="Selecione uma sala"
          class="w-full md:w-14rem"
          :disabled="rooms.length === 0"
          optionValue="id"
        />
      </div>
      <div class="mb-6 flex-1">
        <label class="block mb-2 text-sm text-gray-500"
          >Filtrar por período</label
        >
        <Calendar
          v-model="filterDates"
          selectionMode="range"
          :manualInput="false"
          showIcon
          dateFormat="dd/mm/yy"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md pl-4 bg-white"
        />
      </div>
    </div>

    <div class="flex items-end gap-2">
      <div class="flex-1 flex flex-col"></div>

      <button
        type="button"
        v-if="!loading"
        @click="handleResetFilter"
        class="bg-red-500 text-white py-3 rounded-full px-4 flex items-center gap-2 text-sm hover:brightness-90 transition-all"
      >
        <i class="ph ph-x"></i>

        Resetar filtro
      </button>

      <button
        type="button"
        v-if="!loading"
        @click="handleFilter"
        class="bg-blue-500 text-white py-3 rounded-full px-4 flex items-center gap-2 text-sm hover:brightness-90 transition-all"
      >
        <i class="ph ph-funnel"></i>

        Aplicar filtro
      </button>
      <div v-if="loading" class="flex items-center justify-center">
        <div
          class="w-10 h-10 rounded-full flex border-2 border-t-blue-500 animate-spin"
        ></div>
        <p class="ml-5">Filtrando por favor aguarde...</p>
      </div>
    </div>
  </div>
</template>
<script>
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';

export default {
  name: 'FormFilter',
  components: {
    Dropdown,
    Calendar,
  },

  mounted() {
    this.$store.dispatch('AdminScreenFilterStore/getAllUnits');
  },

  data() {
    return {
      adminFilterService: null,
    };
  },

  computed: {
    filterUnit: {
      get() {
        return this.$store.state.AdminScreenFilterStore.filterUnit;
      },
      set(value) {
        this.$store.commit('AdminScreenFilterStore/SET_FILTER_UNIT', value);
      },
    },

    filterLocal: {
      get() {
        return this.$store.state.AdminScreenFilterStore.filterLocal;
      },
      set(value) {
        this.$store.commit('AdminScreenFilterStore/SET_FILTER_LOCAL', value);
      },
    },

    filterRoom: {
      get() {
        return this.$store.state.AdminScreenFilterStore.filterRoom;
      },
      set(value) {
        this.$store.commit('AdminScreenFilterStore/SET_FILTER_ROOM', value);
      },
    },

    filterDates: {
      get() {
        return this.$store.state.AdminScreenFilterStore.filterDates;
      },
      set(value) {
        this.$store.commit('AdminScreenFilterStore/SET_FILTER_DATES', value);
      },
    },

    loading() {
      return this.$store.state.AdminScreenFilterStore.loading;
    },

    units() {
      return this.$store.state.AdminScreenFilterStore.units;
    },

    locals() {
      return this.$store.state.AdminScreenFilterStore.locals;
    },

    rooms() {
      return this.$store.state.AdminScreenFilterStore.rooms;
    },
  },

  methods: {
    async handleGetLocalsByUnit() {
      this.$store.dispatch('AdminScreenFilterStore/getLocalsByUnit');
    },

    async handleGetRoomsByLocal() {
      this.$store.dispatch('AdminScreenFilterStore/getRoomsByLocal');
    },

    handleFilter() {
      this.$store.dispatch('AdminScreenFilterStore/filter');
    },

    handleResetFilter() {
      this.$store.dispatch('AdminScreenFilterStore/cleanData');
    },
  },
};
</script>
