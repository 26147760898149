<template>
  <Schema>
    <ModalSelectEquipment
      v-show="isOpenModal === true"
      :onCloseModal="handleCloseModal"
      :data="equipmentsDataByLocal"
      :selectedEquipament="selectedEquipament"
      @catchInfoEquipment="catchInfoEquipment"
    />

    <ModalSelectLayout
      :data="roomlayoutsdata"
      :onCloseModal="handleCloseModalSelectLayout"
      v-show="isModalLayout === true"
      @catchInfoLayout="catchInfoLayout"
    />

    <form
      method="post"
      class="w-full max-w-3xl"
      @submit.prevent="handleCreateNewRoom"
    >
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span> • Cadastrar</span>
          <span class="text-blue-500"> • Salas</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Cadastro de salas</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Cadastre as salas do Albert Einstein
        </p>
      </div>

      <section class="flex items-center gap-8 mt-14">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Local

            <select
              required
              @change="getUnitSelectedId"
              v-model="localId"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option value="" selected disabled>Selecione um local</option>
              <option
                v-for="item in locationsdata"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </label>
        </div>
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Unidade

            <input
              type="text"
              placeholder="Unidade atrelada"
              disabled
              :value="unityName"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <section class="flex items-center gap-8 mt-4">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Sala

            <input
              required
              type="text"
              v-model="name"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Número da sala

            <input
              type="text"
              v-model="roomNumber"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <section class="mt-4 flex items-center gap-8">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Descrição

            <input
              type="text"
              v-model="description"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
        <div class="flex-1 flex items-center gap-2">
          <label class="text-sm text-gray-500">
            Tipo de sala

            <select
              required
              v-model="roomTypeId"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option value="" selected disabled>
                Selecione um tipo de sala
              </option>
              <option
                v-for="item in roomtypesdata"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </label>

          <div class="toggle-wrapper">
            <span class="text-sm text-gray-400">Ocultação</span>
            <div class="switch">
              <label>
                <input v-model="occult" type="checkbox" class="toggle-input" />
                <div class="slider">
                  <button class="button"></button>
                </div>
              </label>
            </div>
          </div>

          <div class="toggle-wrapper">
            <span class="text-sm text-gray-400">Manutenção</span>
            <div class="switch">
              <label>
                <input
                  v-model="maintenance"
                  type="checkbox"
                  class="toggle-input"
                />
                <div class="slider">
                  <button class="button"></button>
                </div>
              </label>
            </div>
          </div>
        </div>
      </section>

      <section class="mt-4" v-show="composable === true">
        <label class="text-sm text-gray-500">
          Sala par

          <select
            v-model="composablePair"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          >
            <option value="" selected disabled>Selecione uma sala par</option>
            <option
              v-for="item in roomsdata"
              :key="item.id"
              :value="item.id"
              v-show="item.composable === true"
            >
              {{ item.name }}
            </option>
          </select>
        </label>
      </section>

      <section class="mt-10">
        <div class="flex-1 flex items-center gap-10">
          <div class="flex flex-col gap-2 text-gray-500 text-sm">
            <label class="flex flex-col gap-2">
              Componível
              <div>
                <label class="flex tems-center gap-2">
                  <input type="radio" :value="true" v-model="composable" />
                  Sim
                </label>

                <label class="flex tems-center gap-2">
                  <input type="radio" :value="false" v-model="composable" />
                  Não
                </label>
              </div>
            </label>

            <label class="flex flex-col gap-2 mt-4">
              Auto reserva
              <div>
                <label class="flex tems-center gap-2">
                  <input type="radio" :value="true" v-model="selfReserve" />
                  Sim
                </label>

                <label class="flex tems-center gap-2">
                  <input type="radio" :value="false" v-model="selfReserve" />
                  Não
                </label>
              </div>
            </label>
          </div>

          <button
            type="button"
            class="w-full flex-1 py-16 rounded-lg border-dashed border-2 border-gray-300 text-gray-400 hover:border-blue-500 transition-colors disabled:opacity-75 disabled:cursor-not-allowed disabled disabled:border-red-400 disabled:text-red-400"
            @click="handleOpenModal"
            :disabled="localId === ''"
          >
            Selecionar equipamentos
          </button>
        </div>
      </section>

      <section v-show="selectEquipments.length != 0" class="mt-10">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Equipamentos selecionados

            <div class="max-h-[230px] overflow-y-auto flex flex-col gap-2 mt-2">
              <div
                v-for="item in selectEquipments"
                :key="item.id"
                class="flex items-center justify-between bg-blue-500 px-4 py-2 rounded-lg text-white"
              >
                <div>
                  <h1 class="text-md">{{ item.name }}</h1>
                  <span class="text-cyan-500">{{ item.patrimony }}</span>
                </div>

                <button
                  type="button"
                  @click="handleRemoveEquipmentArray(item.id)"
                  class="w-6 h-6 bg-red-500 flex items-center justify-center rounded-full"
                >
                  <i class="ph ph-trash"></i>
                </button>
              </div>
            </div>
          </label>
        </div>
      </section>

      <section class="mt-6">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Layouts

            <button
              type="button"
              class="w-full border-dashed border-2 border-gray-300 p-2 rounded-lg mt-2 hover:border-blue-500"
              @click="handleOpenModalSelectLayout"
            >
              Selecionar layouts
            </button>
          </label>
        </div>
      </section>

      <section class="mt-6">
        <div class="flex-1">
          <div class="max-h-[200px] overflow-y-auto flex flex-col gap-2">
            <div
              v-for="item in selectLayoutdata"
              :key="item.id"
              class="flex items-center justify-between border-2 border-gray-200 rounded-lg p-2"
            >
              <div class="flex items-center">
                <figure>
                  <img class="w-16" :src="item.imageUrl" alt="item.name" />
                </figure>
                <div class="ml-3 leading-5">
                  <h3 class="text-blue-500">{{ item.name }}</h3>
                  <span class="text-sm text-gray-500"
                    >Capacidade: {{ item.capacity }}</span
                  >
                </div>
              </div>

              <button
                type="button"
                class="bg-red-500 w-10 h-10 rounded-lg text-white flex items-center justify-center hover:brightness-90 transition-all"
                @click="handleRemoveLayoutArray(item.id)"
              >
                <i class="ph ph-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section class="mt-4">
        <label class="text-sm text-gray-500">
          Link da sala virtual

          <input
            type="text"
            v-model="virtualRoomLink"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>
      </section>

      <!-- Button save -->
      <section class="mt-6" v-show="profile">
        <button
          type="submit"
          :disabled="
            localId === '' ||
            name === '' ||
            roomTypeId === '' ||
            isLoadingButton === true
          "
          class="bg-blue-500 py-4 text-white px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
        >
          <SmallLoader v-if="isLoadingButton === true" />

          <span v-else>Salvar sala</span>
        </button>
      </section>
    </form>

    <div class="w-full mt-20" v-show="roomsdata.length > 0">
      <DataTable
        :value="roomsdata"
        :rowClass="rowClass"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        paginator
        :rows="15"
        :rowsPerPageOptions="[15, 20, 30, 50]"
        currentPageReportTemplate="{first} de {last} de {totalRecords}"
        :globalFilterFields="['name', 'roomNumber', 'virtualRoomLink']"
        v-model:filters="filters"
      >
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search pt-1" />
            <input
              v-model="filters['global'].value"
              placeholder="Pesquisa global"
              class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
            />
          </span>
        </template>
        <Column field="name" header="Sala"></Column>
        <Column field="composable" header="Componível">
          <template #body="{ data }">
            <span>{{ data.composable ? 'Sim' : 'Não' }}</span>
          </template>
        </Column>
        <Column field="selfReserve" header="Auto reserva">
          <template #body="{ data }">
            <span>{{ data.selfReserve ? 'Sim' : 'Não' }}</span>
          </template></Column
        >
        <Column field="roomNumber" header="Número da sala"></Column>
        <Column field="virtualRoomLink" header="Reunião">
          <template #body="{ data }">
            <span>{{
              data.virtualRoomLink === '' ? 'Sem reunião' : data.virtualRoomLink
            }}</span>
          </template>
        </Column>
        <Column field="id" header="Editar" class="w-20">
          <template #body="{ data }">
            <button
              type="button"
              class="bg-blue-500 text-white px-4 py-1 rounded-full hover:brightness-90 transition-all"
              @click="handleOpenModalEditRooms(data.id)"
            >
              Editar
            </button>
          </template>
        </Column>
      </DataTable>

      <ModalEditRooms
        :onCloseModal="handleCloseModalEditRooms"
        :id="currentIdModal"
        :data="roomsDataByID"
        :unitName="unitName"
        v-show="isOpenModalEditRoom"
        @getRooms="getRooms"
        :getAllRooms="getAllRooms"
        :equipmentsByLocal="equipmentsByLocal"
        :composablePairFormatted="composablePairFormatted"
      />
    </div>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import SmallLoader from '../../components/Loaders/SmallLoader.vue';
import ModalSelectEquipment from '../../components/Modal/ModalSelectEquipment.vue';
import ModalSelectLayout from '../../components/Modal/ModalSelectLayout.vue';

import api from '../../lib/axios';
import { useToast } from 'vue-toastification';
import Cookies from 'vue-cookies';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

import ModalEditRooms from '../../components/Modal/ModalEditRoom.vue';

export default {
  name: 'RoomView',
  components: {
    Schema,
    ModalSelectEquipment,
    SmallLoader,
    ModalSelectLayout,
    DataTable,
    Column,
    ModalEditRooms,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isOpenModal: false,
      isLoadingButton: false,
      visibleSlide: 0,

      selectedEquipament: '',
      unityName: '',
      roomTypeId: '',
      active: true,
      deleted: false,
      name: '',
      description: '',
      roomNumber: '',
      composable: false,
      selfReserve: false,
      virtualRoomLink: '',
      localId: '',

      isModalLayout: false,
      selectLayoutdata: [],

      roomsComposable: [],
      selectLayoutIds: [],
      selectEquipments: [],
      selectEquipmentsId: [],

      composablePair: '',

      capacityIds: [],

      equipmentsDataByLocal: [],

      maintenance: false,
      occult: false,
      filters: null,

      isOpenModalEditRoom: false,
      currentIdModal: 0,
      roomsDataByID: {},

      unitName: '',
      isLoadingModalButton: false,
      composablePairFormatted: '',

      equipmentsByLocal: [],
      roomsdata: [],
    };
  },
  computed: {
    // Api call locals
    locationsdata() {
      return this.$store.state.Locations.locationsdata;
    },

    // Api call room types
    roomtypesdata() {
      return this.$store.state.RoomTypes.roomtypesdata;
    },

    // Api call room layouts
    roomlayoutsdata() {
      return this.$store.state.RoomLayouts.roomlayoutsdata;
    },

    // Api call equipments
    equipmentsdata() {
      return this.$store.state.Equipments.equipmentsdata;
    },

    profile() {
      return this.$store.state.Default.profile;
    },
  },
  mounted() {
    this.getAllRooms();
    // Api call locals
    this.$store.dispatch('Locations/getLocationsByData', '/locals');

    // Api call room types
    this.$store.dispatch('RoomTypes/getRoomTypesByData', '/roomtypes');

    // Api call equipments
    this.$store.dispatch('Equipments/getEquipmentsByData', '/equipments');

    // Api call room layouts
    this.$store.dispatch('RoomLayouts/getRoomLayoutByData', '/roomlayouts');

    // Api call rooms composable
    this.handleGetRoomsComposable();
  },

  created() {
    this.initFilters();
  },

  methods: {
    getAllRooms() {
      const token = Cookies.get('token');

      api
        .get('/rooms', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.roomsdata = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    rowClass(data) {
      return [{ 'disabled-row': data.active === false }];
    },

    handleOpenModalEditRooms(id) {
      this.currentIdModal = id;
      this.getRooms(id);
      this.isLoadingModalButton = true;
    },

    handleCloseModalEditRooms() {
      this.isOpenModalEditRoom = false;
      this.currentIdModal = 0;
    },

    getRooms(id) {
      const token = Cookies.get('token');

      api
        .get(`/rooms/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.roomsDataByID = res.data;
          this.isOpenModalEditRoom = true;
          this.isLoadingModalButton = false;

          this.getEquipmentsByLocalId(res.data.localId);
          this.getUnitRoom(res.data.getLocalId.unitId);

          this.composablePairFormatted = res.data.composablePair;

          this.roomsDataByID['composablePairName'] = this.roomsdata.find(
            (room) => room.id === this.roomsDataByID.composablePair
          )?.name;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingModalButton = false;
        });
    },

    getUnitRoom(id) {
      const token = Cookies.get('token');

      api
        .get(`/units/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.unitName = res.data.name;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getEquipmentsByLocalId(localId) {
      const token = Cookies.get('token');

      api
        .get(`/equipments/local/${localId}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.equipmentsByLocal = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Function to create new room
    handleCreateNewRoom() {
      const data = {
        active: this.active,
        createdBy: 1,
        updatedBy: 1,
        deleted: this.deleted,
        name: this.name,
        description: this.description,
        roomNumber: this.roomNumber,
        composable: this.composable,
        selfReserve: this.selfReserve,
        virtualRoomLink: this.virtualRoomLink,
        localId: Number(this.localId),
        roomTypeId: Number(this.roomTypeId),
        composablePair: Number(this.composablePair),
        getLayouts: {
          connect: this.selectLayoutIds,
        },
        getEquipments: {
          connect: this.selectEquipmentsId,
        },
        maintenance: this.maintenance,
        occult: this.occult,
      };

      this.isLoadingButton = true;
      const token = this.$cookies.get('token');
      api
        .post('/rooms', data, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          // Reset form
          this.active = true;
          this.deleted = false;
          this.name = '';
          this.description = '';
          this.roomNumber = '';
          this.composable = false;
          this.selfReserve = false;
          this.virtualRoomLink = '';
          this.localId = '';
          this.roomTypeId = '';
          this.roomLayoutId = '';
          this.selectLayoutdata = [];
          this.selectLayoutIds = [];

          this.selectEquipments = [];
          this.selectEquipmentsId = [];

          // Api call rooms
          this.$store.dispatch('Rooms/getRoomsByData', '/rooms');
          this.isLoadingButton = false;

          // Notification success
          this.toast.success('Cadastro de sala realizado com sucesso');

          this.handleUpdateCapacity(res.data.id);
        })
        .catch((err) => {
          if (err.response.status === 500) {
            this.toast.error(
              'Não é permitido criar duas salas com o mesmo nome'
            );
          }
          this.isLoadingButton = false;
          console.log(err);
        });
    },

    handleUpdateCapacity(roomId) {
      const token = Cookies.get('token');

      this.capacityIds.map((item) => {
        api
          .patch(
            `/capacity/${item.id}`,
            {
              roomId: roomId,
            },
            {
              headers: {
                token: token,
              },
            }
          )
          .then((res) => {
            this.capacityIds = [];
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    // Function to select unit by id
    getUnitSelectedId() {
      const token = Cookies.get('token');

      api
        .get(`/locals/${this.localId}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.getUnityByID(res.data.unitId);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Function to api get unit by id
    getUnityByID(id) {
      const token = Cookies.get('token');

      api
        .get(`/units/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.unityName = res.data.name;
        });
    },

    // Function to open modal
    handleOpenModal() {
      this.isOpenModal = true;

      const localIdNumber = Number(this.localId);
      const equipmentoByLocal = this.equipmentsdata.filter(
        (equipment) => equipment.localId === localIdNumber
      );
      this.equipmentsDataByLocal = equipmentoByLocal;
    },

    // Function to close modal
    handleCloseModal() {
      this.isOpenModal = false;
    },

    // Function close modal select layout
    handleCloseModalSelectLayout() {
      this.isModalLayout = false;
    },

    // Function to open modal select layout
    handleOpenModalSelectLayout() {
      this.isModalLayout = true;
    },

    catchName(name) {
      this.selectedEquipament = name;
    },

    catchInfoEquipment(data) {
      this.selectEquipments = [...this.selectEquipments, data];

      this.selectEquipmentsId = [
        ...this.selectEquipmentsId,
        {
          id: data.id,
        },
      ];
    },

    catchInfoLayout(data) {
      this.selectLayoutdata = [...this.selectLayoutdata, data];

      this.selectLayoutIds = [
        ...this.selectLayoutIds,
        {
          id: data.id,
        },
      ];

      // this.capacityIds = [...this.capacityIds, {
      //   id: data.capacityId
      // }]

      this.capacityIds = [
        ...this.capacityIds,
        {
          id: data.capacityId,
        },
      ];
    },

    handleGetRoomsComposable() {
      const token = Cookies.get('token');

      api
        .get('/rooms/composable', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.roomsComposable = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleRemoveLayoutArray(id) {
      const update = this.selectLayoutdata.filter((item) => item.id !== id);
      this.selectLayoutdata = update;

      const updateIds = this.selectLayoutIds.filter((item) => item.id !== id);
      this.selectLayoutIds = updateIds;
    },

    handleRemoveEquipmentArray(id) {
      const updatedSelect = this.selectEquipments.filter(
        (item) => item.id !== id
      );
      this.selectEquipments = updatedSelect;

      const updatedSelectIds = this.selectEquipmentsId.filter(
        (item) => item.id !== id
      );
      this.selectEquipmentsId = updatedSelectIds;
    },
  },
};
</script>
