import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/home/Home.vue';
import Auth from '../views/Auth.vue';
import Reserve from '../views/management/Reserve.vue';
import ReserveDirect from '../views/management/ReserveDirect.vue';

import ManagementOccurrence from '../views/Occurrence/Management.vue';
import ManagementUsers from '../views/management/Users.vue';

import Room from '../views/add/Room.vue';
import Units from '../views/add/Units.vue';
import Locals from '../views/add/Locals.vue';
import Profile from '../views/add/Profile.vue';
import Calendar from '../views/add/Calendar.vue';
import RoomTypes from '../views/add/RoomTypes.vue';
import Equipments from '../views/add/Equipments.vue';
import LayoutRoom from '../views/add/LayoutRoom.vue';

import RehearsalCalendar from '../views/rehearsal/Calendar.vue';
import RehearsalCourse from '../views/rehearsal/Course.vue';
import SchedulingSimulation from '../views/rehearsal/SchedulingSimulation.vue';

import Solicitation from '../views/Solicitation/index.vue';
import Occurrence from '../views/Occurrence/index.vue';

import ConfigEmail from '../views/Email/index.vue';
import Reports from '../views/Reports/index.vue';
import Dashboards from '../views/Reports/Dashboards';

import store from '../store';
import { useToast } from 'vue-toastification';

import Reserves from '../views/management/Reserves.vue';
import Configuration from '../views/configuration';
import AdministrationScreen from '../views/administration-screen';

const toast = useToast();

const routesDefault = [
  {
    path: '/',
    name: 'Auth',
    component: Auth,
  },
  {
    path: '/app',
    name: 'Home',
    component: AdministrationScreen,
    beforeEnter(to, from, next) {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }

        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/gerenciamento/usuarios',
    name: 'Gerenciamento de usuários',
    component: ManagementUsers,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Usuários';

        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    // Finished
    path: '/gerenciamento/reservas',
    name: 'Gerenciamento de reservas',
    component: Reserve,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Reservas';

        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },

  {
    path: '/gerenciamento/reservas/:id',
    name: 'Gerenciamento de uma única reserva',
    component: Reserves,
    beforeEnter(to, from, next) {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        next();
      } else {
        next('/');
      }
    },
  },

  {
    path: '/gerenciamento/reservas/reserva-direta',
    name: 'Reserva direta',
    component: ReserveDirect,
    beforeEnter(to, from, next) {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/ensalamento/add/calendario',
    name: 'Ensalamento calendário',
    component: RehearsalCalendar,
    beforeEnter(to, from, next) {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Ensalamento calendários';

        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },

  {
    path: '/ensalamento/vincular-curso',
    name: 'Vincular curso',
    component: RehearsalCourse,
    beforeEnter(to, from, next) {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Ensalamento vincular cursos';

        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },

  {
    path: '/ensalamento/agendamento',
    name: 'Ensalamento simulação',
    component: SchedulingSimulation,
    beforeEnter(to, from, next) {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Ensalamento simulação';

        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
];

const routesAdd = [
  {
    // Finished
    path: '/add/unidades',
    name: 'Adicionar Unidades',
    component: Units,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Cadastrar Unidades';

        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    // Finished
    path: '/add/layout-da-sala',
    name: 'Adicionar Layout da sala',
    component: LayoutRoom,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Cadastrar Layouts';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    // Finished
    path: '/add/locais',
    name: 'Adicionar Local',
    component: Locals,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Cadastrar Locais';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    // Finished
    path: '/add/equipamentos',
    name: 'Adicionar Equipamentos',
    component: Equipments,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Cadastrar Equipamentos';

        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    // Finished
    path: '/add/tipos-de-sala',
    name: 'Adicionar Tipos de sala',
    component: RoomTypes,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Cadastrar Tipos de Salas';

        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    // Finished
    path: '/add/salas',
    name: 'Adicionar Salas',
    component: Room,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Cadastrar Salas';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    // Finished
    path: '/add/calendario',
    name: 'Adicionar Calendário',
    component: Calendar,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Cadastrar Calendários';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/add/perfil',
    name: 'Adicionar Perfil',
    component: Profile,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Cadastrar Perfis';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/solicitacao',
    name: 'Solicitação',
    component: Solicitation,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Solicitação';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/registro-ocorrencia',
    name: 'Registro de Ocorrência',
    component: Occurrence,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Registro de Ocorrência';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/gerenciamento/ocorrencias',
    name: 'Gerenciamento de ocorrências',
    component: ManagementOccurrence,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Gerenciamento de ocorrências';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/configuracao/email',
    name: 'Configuração de e-mail',
    component: ConfigEmail,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Configuração de e-mail';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },

  {
    path: '/relatorios',
    name: 'Tela de relatórios',
    component: Reports,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Relatórios';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/dashboards',
    name: 'Tela de dashboards',
    component: Dashboards,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Dashboards';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/configuration',
    name: 'Tela de configurações',
    component: Configuration,
    beforeEnter: (to, from, next) => {
      const authenticated = store.state.Auth.isAuthenticated;

      if (authenticated) {
        const AccessProfile = store.state.Auth.Access;
        const pageName = 'Configuração';
        if (store.state.Auth.profileName === 'Técnico (Leitura)') {
          store.commit('Default/CHANGE_PROFILE', false);
        } else {
          store.commit('Default/CHANGE_PROFILE', true);
        }
        const filterName = AccessProfile.filter(
          (item) => item.name === pageName
        );

        if (filterName.length <= 0) {
          next('/app');
          toast.warning('Você não tem acesso a essa rota');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
];

const routes = routesDefault.concat(routesAdd);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
