import router from '../../router';
import api from '../../lib/axios';

import Cookies from 'vue-cookies';
import QueryString from 'qs';
import axios from 'axios';

import { useToast } from 'vue-toastification';
const toast = useToast();

const Auth = {
  namespaced: true,
  state: {
    user: {},
    profiles: [],
    profileName: '',
    Access: [],
    isAuthenticated: false,
    isLoadingAuthMethod: false,
  },

  mutations: {
    CHANGE_USER(state, payload) {
      state.user = payload;
    },

    CHANGE_IS_AUTHENTICATED(state, payload) {
      state.isAuthenticated = payload;
    },

    CHANGE_PROFILES(state, payload) {
      state.profiles = payload;
    },

    CHANGE_LOADING(state, payload) {
      state.isLoadingAuthMethod = payload;
    },

    CHANGE_ACCESS(state, payload) {
      state.Access = payload;
    },

    CHANGE_PROFILENAME(state, payload) {
      state.profileName = payload;
    },
  },

  actions: {
    handleFormSubmitEmailAndPassword({ commit, dispatch }, payload) {
      dispatch('handleGetToken');

      // Dados usuário master
      const id = 1;
      const emailMaster = process.env.VUE_APP_USER_MASTER_EMAIL;
      const passwordMaster = process.env.VUE_APP_USER_MASTER_PASSWORD;

      // Autenticação para o usuário master, sem passar por validação
      if (
        emailMaster === payload.email &&
        passwordMaster === payload.password
      ) {
        commit('CHANGE_LOADING', true);

        // Coloca o id do usuário nos cookies
        Cookies.set('user_id', id);
        const token = Cookies.get('token');

        api
          .get(`/users/${id}`, {
            headers: {
              token,
            },
          })
          .then((response) => {
            Cookies.set('user_info', JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });

        api
          .get('/profiles', {
            headers: {
              token: token,
            },
          })
          .then((res) => {
            // Filtra em todos os perfis e busca pelo administrador
            const filterToProfileAdministrator = res.data.filter(
              (item) => item.name === 'Administrador'
            );

            commit('CHANGE_ACCESS', filterToProfileAdministrator[0].Access);
            commit('CHANGE_PROFILENAME', filterToProfileAdministrator[0].name);
            if (filterToProfileAdministrator.length > 0) {
              // Salva no estado user
              commit('CHANGE_USER', {
                active: true,
                deleted: true,
                createdBy: 1,
                updatedBy: 1,
                name: 'Master',
                given_name: 'Usuário Master',
                email: 'master@ensalamento',
                role: 'Administrador',
                profileId: filterToProfileAdministrator[0].id,
              });

              // Muda o estado de autenticado
              commit('CHANGE_IS_AUTHENTICATED', true);
              commit('CHANGE_LOADING', false);

              // Direciona o usuário para a rota de home
              router.push({ path: '/app' });
            }
          })
          .catch((err) => {
            console.log(err);
            commit('CHANGE_LOADING', false);
          });
      } else {
        commit('CHANGE_LOADING', true);

        // Autenticação pelo RHSSO
        axios({
          method: 'post',
          url: process.env.VUE_APP_ENDPOINT_AUTH,
          data: QueryString.stringify({
            client_id: 'autenticacao',
            client_secret: process.env.VUE_APP_CLIENT_ID,
            grant_type: 'password',
            username: payload.email,
            password: payload.password,
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          },
        })
          .then((res) => {
            dispatch('handleGetUserInfoRHSSO', {
              token: res.data.access_token,
              email: payload.email,
              password: payload.password,
            });
          })
          .catch(() => {
            toast.error('Usuário e/ou senha incorretas');
            commit('CHANGE_LOADING', false);
          });
      }
    },

    // Função que pega o token de autenticação para as chamadas api
    handleGetToken() {
      api
        .get('/token')
        .then((res) => {
          Cookies.set('token', res.data.token);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Função que pega as informações do usuário no RHSSO
    handleGetUserInfoRHSSO({ commit }, payload) {
      axios({
        method: 'GET',
        url: process.env.VUE_APP_ENDPOINT_GET_USER,
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      })
        .then((response) => {
          commit('CHANGE_USER', response.data);
          const token = Cookies.get('token');

          api
            .get('/users', {
              headers: {
                token: token,
              },
            })
            .then((res) => {
              const userEmailRHSSO = response.data.email.toLowerCase().trim();

              // Filtra na lista de usuário e verifica se existe algum email igual a esse
              const userExists = res.data.find(
                (user) => user.email === userEmailRHSSO
              );

              if (!userExists) {
                // Cria um novo usuário no banco de dados
                const token = Cookies.get('token');

                api
                  .get('/profiles', {
                    headers: {
                      token: token,
                    },
                  })
                  .then((res) => {
                    const profileBasic = res.data.find(
                      (item) => item.name === 'Básico'
                    );

                    commit('CHANGE_ACCESS', profileBasic.Access);
                    commit('CHANGE_PROFILENAME', profileBasic.name);
                    Cookies.set(
                      'user_access',
                      JSON.stringify(profileBasic.Access)
                    );

                    const token = Cookies.get('token');

                    const data = {
                      active: true,
                      createdBy: 1,
                      updatedBy: 1,
                      deleted: false,
                      name: response.data.name,
                      email: userEmailRHSSO,
                      drt: 'none',
                      role: profileBasic.name,
                      businessArea: 'test service',
                      profileId: profileBasic.id,
                    };

                    api
                      .post('/users', data, {
                        headers: {
                          token: token,
                        },
                      })
                      .then((res) => {
                        Cookies.set('user_id', res.data.id);
                        Cookies.set('user_info', JSON.stringify(res.data));
                        // Troca de false pra true uma variável para liberar rotas
                        commit('CHANGE_IS_AUTHENTICATED', true);
                        commit('CHANGE_LOADING', false);

                        // Direciona o usuário para a tela de home
                        router.push({ path: '/app' });
                      })
                      .catch((err) => {
                        console.log(err);
                        commit('CHANGE_LOADING', false);
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                    commit('CHANGE_LOADING', false);
                  });
              } else {
                // Pega os dados do usuário pelo e-mail e salva em variáveis
                const token = Cookies.get('token');

                api
                  .post(
                    `/users/getemail`,
                    {
                      email: userEmailRHSSO,
                    },
                    {
                      headers: {
                        token: token,
                      },
                    }
                  )
                  .then((res) => {
                    const token = Cookies.get('token');

                    api
                      .get(`/profiles/${res.data.profileId}`, {
                        headers: {
                          token: token,
                        },
                      })
                      .then((res) => {
                        commit('CHANGE_ACCESS', res.data.Access);
                        commit('CHANGE_PROFILENAME', res.data.name);
                        Cookies.set(
                          'user_access',
                          JSON.stringify([res.data.Access])
                        );
                      })
                      .catch((err) => {
                        console.log(err);
                      });

                    Cookies.set('user_id', res.data.id);
                    Cookies.set('user_info', JSON.stringify(res.data));

                    commit('CHANGE_IS_AUTHENTICATED', true);
                    commit('CHANGE_LOADING', false);
                    router.push({ path: '/app' });
                  })
                  .catch((err) => {
                    console.log(err);
                    commit('CHANGE_LOADING', false);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              commit('CHANGE_LOADING', false);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Função para pegar todos os perfis
    handleGetProfiles({ commit }) {
      const token = Cookies.get('token');

      api
        .get('/profiles', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          commit('CHANGE_PROFILES', res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default Auth;
