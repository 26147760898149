<template>
  <Schema>
    <form
      class="w-full max-w-3xl"
      @submit.prevent="handleCreateNewReserveDirect"
    >
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <router-link
            to="/gerenciamento/reservas"
            class="hover:text-blue-500 transition-colors"
          >
            • Reservas
          </router-link>
          <span class="text-blue-500"> • Reserva direta</span>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex flex-col">
          <h1 class="text-3xl text-gray-600">Solicitação de reserva direta</h1>
          <p class="text-gray-400 max-w-xs mt-1">
            Abra um chamado do reserva direta
          </p>
        </div>
      </div>

      <section class="flex items-center gap-6 mt-14">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Nome do usuário

            <input
              type="text"
              :value="user.name"
              disabled
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>

        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Ramal/Telefone

            <input
              v-model="branch"
              type="text"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <section class="flex items-center gap-6 mt-4">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            E-mail do usuário

            <input
              type="text"
              :value="user.email"
              disabled
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>

        <div class="flex-1">
          <label class="text-sm text-gray-500">
            E-mail solicitante

            <input
              v-model="requesterEmail"
              type="text"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <section class="flex items-center gap-6 mt-4">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Unidade

            <select
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              @change="handleUnitChange"
              v-model="unitId"
            >
              <option value="" selected disabled>Selecione uma sala</option>
              <option v-for="unit in units" :key="unit.id" :value="unit.id">
                {{ unit.name }}
              </option>
            </select>
          </label>
        </div>

        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Local

            <select
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              @change="handleLocalChange"
              :disabled="!unitId"
              v-model="localId"
            >
              <option value="" selected disabled>Selecione uma sala</option>
              <option v-for="local in locals" :key="local.id" :value="local.id">
                {{ local.name }}
              </option>
            </select>
          </label>
        </div>
      </section>

      <section class="flex items-center gap-6 mt-4">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Sala

            <select
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              v-model="roomId"
              :disabled="!localId"
            >
              <option value="" selected disabled>Selecione uma sala</option>
              <option v-for="room in roomsdata" :key="room.id" :value="room.id">
                {{ room.name }}
              </option>
            </select>
          </label>
        </div>

        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Descrição do evento/curso

            <input
              type="text"
              v-model="eventDescription"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <section class="flex items-center gap-6 mt-4">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Data

            <Calendar
              v-model="date"
              showIcon
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              dateFormat="dd/mm/yy"
              :manualInput="false"
              @date-select="getNameDayOfWeekByDate"
            />
          </label>
        </div>

        <div class="flex-1 flex items-center gap-4">
          <label class="text-sm text-gray-500 flex-1">
            Horário de início

            <input
              type="time"
              v-model="startTime"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>

          <label class="text-sm text-gray-500 flex-1">
            Horário de término

            <input
              type="time"
              v-model="finalTime"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <section class="flex items-center gap-6 mt-4">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Tornar recorrente

            <select
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              v-model="recurrent"
              :disabled="!date"
              @change="handleRecurrentChange"
            >
              <option value="" disabled selected>Selecione uma opção</option>
              <option :value="true">Sim</option>
              <option :value="false">Não</option>
            </select>
          </label>
        </div>

        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Centro de custo

            <input
              v-model="costCenter"
              type="text"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <RecurrentSolicitation
        v-show="recurrent && isChoiceComposable"
        :recurrentSolicitationDataForm="recurrentSolicitationDataForm"
      />

      <CustomRecurrence
        v-show="recurrent && isCustomRecurrence && recurrenceList.length"
        :recurrenceList="recurrenceList"
        @handleAddRecurrenceItem="handleAddRecurrenceItem"
        @handleRemoveRecurrenceItem="handleRemoveRecurrenceItem"
      />

      <!-- Button save -->
      <section class="mt-6">
        <button
          type="submit"
          :disabled="handleBottomDisabled()"
          class="bg-blue-500 py-4 text-white px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
        >
          Salvar reserve direta
        </button>
      </section>
    </form>
    <ComposableRoomDialog
      :displayDialog="displayDialog"
      :composableRooms="composableRooms"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirmCreateNewReserveDirect"
    />
    <ChoiceComposableDialog
      :displayChoiceComposableDialog="displayChoiceComposableDialog"
      @handleRecurrenceDefault="handleChoiceComposable"
      @handleCustomRecurrence="handleChoiceComposable"
    />
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import Cookies from 'vue-cookies';
import {
  useSolicitationService,
  useUnitService,
  useRoomsService,
  useLocalsService,
  useAppointmentService,
  useHelperService,
} from '../../services';
import RecurrentSolicitation from '../Solicitation/components/recurrent-solicitation.vue';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { messageSuccess, messageWarning } from '../../lib/toast';
import Calendar from 'primevue/calendar';
import ComposableRoomDialog from '../../components/ComposableRoomDialog.vue';
import ChoiceComposableDialog from './components/ChoiceComposableDialog.vue';
import CustomRecurrence from './components/CustomRecurrence.vue';

export default {
  name: 'ReserveDirectView',

  components: {
    Schema,
    RecurrentSolicitation,
    Calendar,
    ComposableRoomDialog,
    ChoiceComposableDialog,
    CustomRecurrence,
  },

  data() {
    return {
      localId: null,
      unitId: null,
      roomId: null,
      eventDescription: '',
      requesterEmail: '',
      branch: '',
      date: '',
      startTime: '',
      finalTime: '',
      costCenter: '',
      recurrent: false,
      recurrentPattern: '',
      roomsdata: [],
      roomsBackup: [],
      locals: [],
      units: [],
      solicitationService: useSolicitationService(),
      unitService: useUnitService(),
      roomsService: useRoomsService(),
      localsService: useLocalsService(),
      appointmentService: useAppointmentService(),
      useHelperService: useHelperService(),
      recurrentSolicitationDataForm: {
        defaultRecurrent: 'Diário',
        toggleMonday: true,
        toggleTuesday: true,
        toggleWednesday: true,
        toggleThursday: true,
        toggleFriday: true,
        toggleSaturday: true,
        toggleSunday: true,
        valueMonday: 'segunda-feira',
        valueTuesday: 'terça-feira',
        valueWednesday: 'quarta-feira',
        valueThursday: 'quinta-feira',
        valueFriday: 'sexta-feira',
        valueSaturday: 'sábado',
        valueSunday: 'domingo',
        activeMonday: false,
        activeTuesday: false,
        activeWednesday: false,
        activeThursday: false,
        activeFriday: false,
        activeSaturday: false,
        activeSunday: false,
        endDate: '',
        repeatWeek: '1',
      },
      appointments: [],
      roomIdsToApprove: [],
      finalDate: '',
      composableRooms: {},
      displayDialog: false,
      isChoiceComposable: false,
      isCustomRecurrence: false,
      displayChoiceComposableDialog: false,
      recurrenceList: [],
    };
  },

  computed: {
    user() {
      return this.$store.state.Auth.user;
    },

    isLoading: {
      set(value) {
        this.$store.commit('Default/CHANGE_ISLOADING', value);
      },
    },
  },

  mounted() {
    this.handleLoadUnits();
  },

  methods: {
    getAppointmentsDateForward(date) {
      this.appointmentService
        .getAppointmentsDateForward({ date: date })
        .then((appointments) => {
          this.appointments = appointments;
        });
    },

    handleLoadUnits() {
      this.isLoading = true;

      this.unitService
        .getUnits()
        .then((res) => {
          this.units = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getNameDayOfWeekByDate() {
      const nameDayOfWeek = format(this.date, 'EEEE', {
        locale: ptBR,
      });

      this.recurrentSolicitationDataForm.activeMonday = false;
      this.recurrentSolicitationDataForm.activeTuesday = false;
      this.recurrentSolicitationDataForm.activeWednesday = false;
      this.recurrentSolicitationDataForm.activeThursday = false;
      this.recurrentSolicitationDataForm.activeFriday = false;
      this.recurrentSolicitationDataForm.activeSaturday = false;
      this.recurrentSolicitationDataForm.activeSunday = false;

      if (this.recurrentSolicitationDataForm.valueMonday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeMonday = true;
      }

      if (this.recurrentSolicitationDataForm.valueTuesday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeTuesday = true;
      }

      if (this.recurrentSolicitationDataForm.valueWednesday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeWednesday = true;
      }

      if (this.recurrentSolicitationDataForm.valueThursday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeThursday = true;
      }

      if (this.recurrentSolicitationDataForm.valueFriday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeFriday = true;
      }

      if (this.recurrentSolicitationDataForm.valueSaturday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeSaturday = true;
      }

      if (this.recurrentSolicitationDataForm.valueSunday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeSunday = true;
      }

      this.getAppointmentsDateForward(format(this.date, 'yyyy-MM-dd'));
    },

    handleUnitChange(event) {
      const unitId = Number(event.target.value);
      this.isLoading = true;

      this.localsService
        .getLocals()
        .then((res) => {
          this.locals = res.filter((local) => local.unitId === unitId);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleLocalChange(event) {
      const localId = Number(event.target.value);
      this.isLoading = true;

      this.roomsService
        .getRooms()
        .then((res) => {
          this.roomsBackup = res;
          this.roomsdata = res.filter((room) => room.localId === localId);

          this.roomsdata = this.roomsdata.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async handleCreateNewReserveDirect() {
      this.finalDate = this.date;

      this.mountRoomIdsList();

      if (
        this.recurrent &&
        this.isCustomRecurrence &&
        this.recurrenceList.length
      ) {
        if (this.roomIdsToApprove.length > 1 && this.composableRooms) {
          this.displayDialog = true;
        } else {
          await this.createDirectSolicitationCustomRecurrent();
        }
        return;
      }

      for (let roomId of this.roomIdsToApprove) {
        if (this.recurrent) {
          await this.handleLoadRecurrentSolicitation();
          const decoded = JSON.parse(this.recurrentPattern);

          for (let recurrentPattern of decoded.recurrent) {
            const payload = {
              date: recurrentPattern.currentDate,
              startTime: this.startTime,
              endTime: this.finalTime,
              roomId: roomId,
            };

            const isBlocked = this.useHelperService.checkAvailableRooms(
              payload,
              this.appointments
            );

            if (isBlocked) {
              messageWarning(
                'Essa sala já possuí agendamentos no dia e horário informado'
              );
              return;
            }
          }

          this.finalDate = new Date(
            decoded.recurrent[decoded.recurrent.length - 1].currentDate +
              ' ' +
              '00:00:00'
          );
        }

        const payload = {
          localId: this.localId,
          initialTime: this.startTime,
          finalTime: this.finalTime,
          date: format(this.date, 'yyyy-MM-dd'),
        };

        const result = await this.localsService.verifyLocalWorkingTime(payload);

        if (result && result.blocked) {
          messageWarning(result.status);
          return;
        }

        if (!this.recurrent) {
          const payloadToCheck = {
            date: format(this.date, 'yyyy-MM-dd'),
            startTime: this.startTime,
            endTime: this.finalTime,
            roomId: roomId,
          };

          const isBlocked = this.useHelperService.checkAvailableRooms(
            payloadToCheck,
            this.appointments
          );

          if (isBlocked) {
            messageWarning(
              'Essa sala já possuí agendamentos no dia e horário informado'
            );
            return;
          }
        }
      }

      if (this.roomIdsToApprove.length > 1 && this.composableRooms) {
        this.displayDialog = true;
      } else {
        this.handleConfirmCreateNewReserveDirect();
      }
    },

    async createDirectSolicitationCustomRecurrent() {
      this.isLoading = true;

      const payload = {
        ...this.objectToSaveSolicitation(),
        recurrenceList: this.recurrenceList.map((item) => {
          return {
            ...item,
            date: format(item.date, 'yyyy-MM-dd'),
          };
        }),
        roomIds: this.roomIdsToApprove,
      };

      const result =
        await this.solicitationService.createDirectSolicitationCustomRecurrent(
          payload
        );

      if (result && result.id && result.Appointments.length) {
        const appointments = result.Appointments;
        const status =
          this.solicitationService.verifyStatusSolicitation(appointments);

        await this.solicitationService
          .updateSolicitation(result.id, { status })
          .then(() => {
            messageSuccess('Reserva direta criada com sucesso');
            this.$router.push({ path: '/gerenciamento/reservas' });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    async handleConfirmCreateNewReserveDirect() {
      if (
        this.recurrent &&
        this.isCustomRecurrence &&
        this.recurrenceList.length
      ) {
        await this.createDirectSolicitationCustomRecurrent();
        return;
      }

      const data = this.objectToSaveSolicitation();

      this.isLoading = true;

      this.solicitationService
        .createDirectSolicitation(data)
        .then(() => {
          messageSuccess('Reserva direta criada com sucesso');
          this.$router.push({ path: '/gerenciamento/reservas' });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async handleLoadRecurrentSolicitation() {
      const data = {
        name: this.eventName,
        currentDate: format(this.date, 'yyyy-MM-dd'),
        lastDate: format(
          this.recurrentSolicitationDataForm.endDate,
          'yyyy-MM-dd'
        ),
        days: [
          {
            name: 'Segunda-feira',
            active: this.recurrentSolicitationDataForm.toggleMonday,
          },
          {
            name: 'Terça-feira',
            active: this.recurrentSolicitationDataForm.toggleTuesday,
          },
          {
            name: 'Quarta-feira',
            active: this.recurrentSolicitationDataForm.toggleWednesday,
          },
          {
            name: 'Quinta-feira',
            active: this.recurrentSolicitationDataForm.toggleThursday,
          },
          {
            name: 'Sexta-feira',
            active: this.recurrentSolicitationDataForm.toggleFriday,
          },
          {
            name: 'Sábado',
            active: this.recurrentSolicitationDataForm.toggleSaturday,
          },
          {
            name: 'Domingo',
            active: this.recurrentSolicitationDataForm.toggleSunday,
          },
        ],
        defaultRecurrent: this.recurrentSolicitationDataForm.defaultRecurrent,
        repeatWeek: Number(this.recurrentSolicitationDataForm.repeatWeek),
      };

      await this.solicitationService
        .recurrentSolicitation(data)
        .then((res) => {
          this.recurrentPattern = JSON.stringify(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    mountRoomIdsList() {
      this.roomIdsToApprove = [this.roomId];

      const room1 = this.roomsdata.find(
        (room) => room.id === Number(this.roomId)
      );

      if (room1 && room1.composable) {
        const room2 = this.roomsdata.find(
          (room) => room.id === room1.composablePair
        );

        this.roomIdsToApprove.push(room2.id);

        this.composableRooms = {
          room1: room1.name,
          room2: room2.name,
        };
      }
    },

    handleCloseDialog() {
      this.roomIdsToApprove = [this.roomId];
      this.handleConfirmCreateNewReserveDirect();
      this.displayDialog = false;
    },

    handleChoiceComposable(isChose) {
      this.isChoiceComposable = isChose;
      this.displayChoiceComposableDialog = false;
      this.recurrenceList = [];

      if (!isChose) {
        this.isCustomRecurrence = true;
        this.recurrenceList = [
          {
            id: 1,
            date: null,
            startTime: this.startTime,
            finalTime: this.finalTime,
          },
        ];
      }
    },

    handleRecurrentChange() {
      this.isChoiceComposable = false;
      this.isCustomRecurrence = false;

      if (this.recurrent) {
        this.displayChoiceComposableDialog = true;
      }
    },

    handleAddRecurrenceItem() {
      this.recurrenceList.push({
        id: this.recurrenceList.length + 1,
        date: null,
        startTime: this.startTime,
        finalTime: this.finalTime,
      });
    },

    handleRemoveRecurrenceItem(id) {
      this.recurrenceList = this.recurrenceList.filter(
        (item) => item.id !== id
      );
    },

    objectToSaveSolicitation() {
      const userId = Cookies.get('user_id');

      return {
        active: true,
        deleted: false,
        createdBy: Number(userId),
        name: this.user.name,
        branch: this.branch,
        email: this.user.email,
        requesterEmail: this.requesterEmail,
        roomId: Number(this.roomId),
        unitId: Number(this.unitId),
        localId: Number(this.localId),
        date: format(this.date, 'yyyy-MM-dd'),
        finalDate: this.finalDate,
        startTime: this.startTime,
        finalTime: this.finalTime,
        costCenter: this.costCenter,
        recurrent: this.recurrent,
        recurrentPattern: this.recurrentPattern,
        status: 'Aprovada',
        eventDescription: this.eventDescription,
        eventName: this.eventDescription,
        roomIds: this.roomIdsToApprove,
        roomType: 'Presencial',
      };
    },

    handleBottomDisabled() {
      if (
        this.requesterEmail === '' ||
        this.branch === '' ||
        this.date === '' ||
        this.startTime === '' ||
        this.finalTime === '' ||
        this.costCenter === '' ||
        this.localId === '' ||
        this.unitId === '' ||
        this.roomId === '' ||
        this.eventDescription === ''
      ) {
        return true;
      }

      if (
        this.recurrent &&
        this.isChoiceComposable &&
        (this.recurrentSolicitationDataForm.defaultRecurrent === '' ||
          this.recurrentSolicitationDataForm.endDate === '')
      ) {
        return true;
      }

      if (
        this.recurrent &&
        !this.isChoiceComposable &&
        this.recurrenceList.some(
          (item) =>
            item.date === null ||
            item.startTime === null ||
            item.finalTime === null
        )
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>
