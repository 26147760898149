<template>
  <Dialog
    v-model:visible="this.$props.isOpenModalDetailSolicitation"
    :style="{ width: '60vw' }"
    :modal="true"
    :showHeader="false"
  >
    <button
      @click="handleCloseModal()"
      class="float-right bg-blue-500 text-white rounded-full py-2 w-9 h-9 mt-3 justify-self-end"
    >
      <i class="ph ph-x"></i>
    </button>
    <h1 class="border-b-2 mt-5 mb-3 text-blue-500 pb-5 text-xl">
      Detalhes da solicitação
    </h1>
    <div class="bg-gray-100 rounded-md mt-5 mb-5 p-5">
      <div class="flex justify-between">
        <h1 class="text-2xl">
          Solicitação: <b class="ml-1">#{{ solicitationData.id }}</b>
        </h1>
        <h1><b class="ml-1">Tipo de evento: </b> {{ solicitationData.id }}</h1>
      </div>
      <div class="flex justify-between">
        <h1><b>Solicitada por: </b>{{ solicitationData.name }}</h1>
        <h1>
          <b class="ml-1">E-mail: </b>{{ solicitationData.requesterEmail }}
        </h1>
        <h1><b class="ml-1">Telefone: </b>{{ solicitationData.branch }}</h1>
      </div>
    </div>
    <DataTable
      :value="appointmentsData"
      paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
      paginator
      :rows="10"
      :rowsPerPageOptions="[10, 20, 30, 50]"
      currentPageReportTemplate="{first} de {last} de {totalRecords}"
      v-model:filters="filters"
      :globalFilterFields="[
        'dateFormatted',
        'startTime',
        'finalTime',
        'getApprovedBy.name',
        'status',
        'getRoom.name',
      ]"
    >
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search pt-1" />
          <input
            v-model="filters['global'].value"
            placeholder="Pesquisa global"
            class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
          />
        </span>
      </template>
      <Column field="dateFormatted" header="Data"> </Column>
      <Column header="Horário">
        <template #body="{ data }">
          {{ data.startTime }} - {{ data.finalTime }}
        </template>
      </Column>
      <Column field="solicitationData.name" header="Aprovação">
        <template #body="{ data }">
          {{ data.getApprovedBy ? data.getApprovedBy.name : '-' }}
        </template>
      </Column>
      <Column header="Status">
        <template #body="{ data }">
          <div
            :style="
              data.status === 'Pendente'
                ? { color: '#cdb207' }
                : data.status === 'Aprovada'
                ? { color: '#43A047' }
                : data.status === 'Cancelada'
                ? { color: '#F44336' }
                : data.status === 'Reprovada'
                ? { color: '#F38A33' }
                : ''
            "
          >
            {{ data.status ? data.status : '-' }}
          </div>
        </template></Column
      >
      <Column header="Sala">
        <template #body="{ data }">
          {{ data.getRoom ? data.getRoom.name : '-' }}
        </template>
      </Column>
      <Column header="Ações" class="w-32">
        <template #body="{ data }">
          <ConfirmPopup />
          <button
            class="bg-green-500 w-[31px] h-[31px] text-xl mr-2 pt-0.5 justify-center text-white rounded-full hover:brightness-90 transition-all disabled:bg-gray-300 disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
            type="button"
            @click="handleConfirm($event, 'Aprovada', data)"
            :disabled="
              data.status === 'Aprovada' ||
              this.$props.userInfo.role === 'Básico' ||
              (this.$props.roomType !== 'On-line' && data.roomId === null)
            "
          >
            <i class="ph ph-check"></i>
          </button>
          <button
            class="bg-red-500 w-[31px] h-[31px] text-xl mr-2 pt-0.5 justify-center text-white rounded-full hover:brightness-90 transition-all disabled:bg-gray-300 disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
            type="button"
            @click="handleConfirm($event, 'Cancelada', data)"
            :disabled="
              data.status === 'Cancelada' ||
              (this.$props.roomType !== 'On-line' && data.roomId === null)
            "
          >
            <i class="ph ph-x"></i>
          </button>
          <button
            class="bg-blue-400 w-[31px] h-[31px] text-xl pt-0.5 justify-center text-white rounded-full hover:brightness-90 transition-all disabled:bg-gray-300 disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
            type="button"
            @click="handleOpenModalRoomChange(data)"
            :disabled="handleBottomDisabled(data)"
          >
            <i class="ph ph-gear"></i>
          </button>
        </template>
      </Column>
    </DataTable>
    <div class="flex justify-end">
      <button
        @click="handleCloseModal()"
        class="bg-blue-500 text-white w-48 px-10 text-xl flex items-center justify-center mt-5 gap-2 py-3 ml-2 rounded-full hover:brightness-90 transition-all border-2 border-blue-500"
      >
        Ok
      </button>
    </div>
  </Dialog>
  <ComposableRoomDialog
    :displayDialog="displayDialog"
    :composableRooms="composableRooms"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleRoomComposableConfirm"
  />
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { messageSuccess, messageWarning } from '../../../lib/toast';
import {
  useAppointmentService,
  useLocalsService,
  useRoomsService,
  useSolicitationService,
} from '../../../services';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmPopup from 'primevue/confirmpopup';
import ComposableRoomDialog from '../../../components/ComposableRoomDialog.vue';

export default {
  name: 'DialogDetailSolicitation',
  components: {
    DataTable,
    Column,
    ConfirmPopup,
    ComposableRoomDialog,
  },

  props: {
    isOpenModalDetailSolicitation: {
      type: Boolean,
      required: true,
    },

    solicitationData: {
      type: Object,
      required: true,
    },

    appointmentsData: {
      type: Array,
      required: true,
    },

    userInfo: {
      type: Object,
      required: true,
    },

    roomType: {
      type: String,
      required: true,
    },
  },

  created() {
    this.initFilters();
  },

  data() {
    return {
      filters: null,
      appointmentService: useAppointmentService(),
      localsService: useLocalsService(),
      roomsService: useRoomsService(),
      solicitationService: useSolicitationService(),
      confirm: useConfirm(),
      displayDialog: false,
      appointmentData: null,
      composableRooms: null,
      statusSelected: null,
      isComposable: false,
      composablePairRoomId: null,
      optionComposableSelected: false,
      appointmentsToUpdate: [],
    };
  },

  computed: {
    isLoading: {
      set(value) {
        this.$store.commit('Default/CHANGE_ISLOADING', value);
      },
    },
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    handleCloseModal() {
      this.$emit('handleCloseModalDetailSolicitation');
    },

    handleOpenModalRoomChange(roomToChange) {
      this.$emit('handleOpenModalRoomChange', roomToChange);
    },

    async handleConfirm(event, status, item) {
      this.appointmentData = item;
      this.statusSelected = status;
      this.confirm.require({
        target: event.currentTarget,
        message:
          status === 'Aprovada'
            ? 'Aprovar solicitação?'
            : 'Cancelar solicitação?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {
          this.handleOpenDialog();
        },
        reject: () => {},
      });
    },

    async handleOpenDialog() {
      this.isComposable = false;
      this.composablePairRoomId = null;
      this.optionComposableSelected = false;

      this.appointmentsToUpdate = [];
      this.appointmentsToUpdate = [this.appointmentData];

      if (this.statusSelected === 'Aprovada') {
        if (this.roomType === 'On-line') {
          this.handleEditAppointments();
          return;
        }

        this.isLoading = true;

        const result = await this.roomsService
          .getRoomById(this.appointmentData.getRoom.id)
          .finally(() => {
            this.isLoading = false;
          });

        if (!result) {
          messageWarning('Não foi possível encontrar a sala!');
          return;
        }

        if (result && result.composable) {
          this.composableRooms = {
            room1: result.name,
            room2: result.roomComposable.name,
          };

          this.composablePairRoomId = result.roomComposable.id;
          this.displayDialog = true;

          const appoitments = this.appointmentsData.filter(
            (appointment) =>
              appointment.roomId === this.composablePairRoomId &&
              appointment.date === this.appointmentData.date
          );

          this.appointmentsToUpdate = [
            ...this.appointmentsToUpdate,
            ...appoitments,
          ];
        } else {
          this.handleEditAppointments();
        }
      } else {
        this.handleEditAppointments();
      }
    },

    async handleRoomComposableConfirm() {
      this.displayDialog = false;
      this.isComposable = true;
      this.optionComposableSelected = true;
      this.handleEditAppointments();
    },

    handleCloseDialog() {
      this.displayDialog = false;
      this.isComposable = true;
      this.optionComposableSelected = false;
      this.handleEditAppointments();
    },

    async handleEditAppointments() {
      if (!this.appointmentData) {
        messageWarning('Não foi possível encontrar a solicitação!');
        return;
      }

      this.isLoading = true;

      if (this.statusSelected === 'Aprovada') {
        const payload = {
          date: this.appointmentData.date,
          startTime: this.appointmentData.startTime,
          finalTime: this.appointmentData.finalTime,
          roomId: this.appointmentData.roomId,
        };

        const checkIsBlocked = await this.appointmentService.checkIsBlocked(
          payload
        );

        if (checkIsBlocked && this.roomType !== 'On-line') {
          this.displayDialog = false;
          messageWarning(
            `A Sala: ${this.appointmentData.getRoom.name} já reservada para esse horário!`
          );
          this.isLoading = false;
          return;
        }

        const payloadToCheckLocal = {
          localId: this.solicitationData.localId,
          initialTime: this.appointmentData.startTime,
          finalTime: this.appointmentData.finalTime,
          date: this.appointmentData.date,
        };

        const result = await this.localsService.verifyLocalWorkingTime(
          payloadToCheckLocal
        );

        if (result && result.blocked && this.roomType !== 'On-line') {
          this.displayDialog = false;
          messageWarning(result.status);
          this.isLoading = false;
          return;
        }

        const dataUpdateAppointment = {
          solicitationId: this.solicitationData.id,
          roomType: this.solicitationData.roomType,
          status: this.statusSelected,
          localId: this.solicitationData.localId,
          approvedBy: Number(this.userInfo.id),
          isComposable: this.isComposable,
          composablePairRoomId: this.composablePairRoomId,
          appointments: this.appointmentsToUpdate,
          optionComposableSelected: this.optionComposableSelected,
        };

        await this.appointmentService.updateStatus(dataUpdateAppointment);

        this.displayDialog = false;
      } else if (this.statusSelected === 'Cancelada') {
        await this.appointmentService.updateAppointment(
          Number(this.appointmentData.id),
          {
            status: this.statusSelected,
            approvedBy: this.userInfo.id,
          }
        );

        messageSuccess('Solicitação cancelada com sucesso!');
      }

      const resultSolicitation =
        await this.solicitationService.getSolicitationId(
          this.solicitationData.id
        );

      if (!resultSolicitation) {
        messageWarning('Não foi possível encontrar a solicitação!');
        return;
      }

      const statusSolicitationToUpdate =
        this.solicitationService.verifyStatusSolicitation(
          resultSolicitation.Appointments
        );

      const payloadSoliciationToUpdate = {
        status:
          this.roomType === 'On-line'
            ? this.statusSelected
            : statusSolicitationToUpdate,
        updatedBy: this.userInfo.id,
      };

      await this.solicitationService
        .updateSolicitation(
          this.solicitationData.id,
          payloadSoliciationToUpdate
        )
        .finally(() => {
          this.isLoading = false;
        });

      messageSuccess('Solicitação aprovada com sucesso!');
      this.$emit('handleChangeRoomConfirmation');
    },

    handleBottomDisabled(data) {
      if (
        (this.$props.userInfo.role === 'Básico' &&
          data.status !== 'Pendente') ||
        this.$props.roomType === 'On-line' ||
        (this.$props.roomType !== 'On-line' && data.roomId === null)
      ) {
        return true;
      }

      const getRoom = this.solicitationData.getRoomId;

      if (getRoom && getRoom.composable) {
        return true;
      }

      return false;
    },
  },
};
</script>
