<template>
  <div
    v-show="!profile"
    class="bg-blue-500 text-center text-white h-6 text-sm flex items-center justify-center"
  >
    Modo leitura está ativado para o seu usuário
  </div>
  <div
    v-show="profile"
    class="bg-blue-500 text-center text-white h-6 block"
  ></div>

  <div
    class="w-full h-[calc(100vh-1.5rem)] flex bg-authDark bg-center bg-cover bg-no-repeat"
  >
    <Sidebar />
    <div class="flex-1 flex flex-col">
      <header
        class="w-full h-24 bg-white border-b border-gray-300 flex items-center justify-between px-8 shadow-sm"
      >
        <button
          type="button"
          class="flex items-center text-blue-500 justify-center lg:hidden"
        >
          <i class="ph ph-list text-2xl"> </i>
        </button>

        <div class="hidden lg:flex items-center gap-2 text-blue-500">
          <i class="ph ph-clock text-xl"> </i>
          <time :datetime="currentDate" :title="currentDate" class="text-md">
            {{ currentDate }}
          </time>
        </div>

        <div class="flex items-center">
          <div class="hidden md:flex items-center gap-2 py-3">
            <button
              type="button"
              title="Suas notificações"
              class="text-2xl w-10 h-10 rounded-lg flex items-center justify-center text-gray-500 hover:bg-blue-500 hover:text-white transition-colors"
            >
              <i class="ph ph-bell"> </i>
            </button>
            <button
              type="button"
              title="Configurações de perfil"
              class="text-2xl w-10 h-10 rounded-lg flex items-center justify-center text-gray-500 hover:bg-blue-500 hover:text-white transition-colors"
            >
              <i class="ph ph-gear"> </i>
            </button>
          </div>

          <div class="border-r flex items-center pr-6 mx-6">
            <div>
              <span
                class="inline-flex w-12 h-12 rounded-full flex items-center justify-center uppercase text-white bg-blue-500"
              >
                {{ firstLetterName }}
              </span>
            </div>
            <div class="mx-5 leading-5" :title="user.given_name">
              <h3 class="text-blue-500">
                {{ !user.given_name ? 'Usuário anônimo' : user.given_name }}
              </h3>
              <span class="text-gray-500 text-sm">{{ user.email }}</span>
            </div>
          </div>

          <button
            type="button"
            class="bg-blue-500 w-10 h-10 rounded-lg text-white flex items-center justify-center text-lg text-gray-500 hover:bg-red-500 transition-colors"
            title="Clique para sair da plataforma"
            @click="handleSignOut()"
          >
            <i class="ph ph-sign-out"></i>
          </button>
        </div>
      </header>

      <div class="flex-1 flex flex-col overflow-y-auto px-8 py-8 relative" ref="myDiv">
        <div
          class="flex-1 bg-white p-8 rounded-2xl shadow-lg flex items-center justify-center flex-col"
        >
          <slot />
        </div>
        <button class="go-to-top" @click="scrollToTop">
          <i class="pi pi-arrow-up"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '../Sidebar/index.vue';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export default {
  name: 'SchemaDefaultItem',
  components: {
    Sidebar,
  },

  data() {
    return {
      search: '',
      access_token: '',
      currentDate: '',
      firstLetterName: '',
      usersdata: [],
    };
  },

  computed: {
    profile() {
      return this.$store.state.Default.profile;
    },

    user() {
      return this.$store.state.Auth.user;
    },
  },

  mounted() {
    // Chama a função de pegar data e primeira letra, quando a tela é carregada
    this.getCurrentDate();
  },

  methods: {
    // Função para sair da plataforma, destruindo cookies e mudando variável de autenticação
    handleSignOut() {
      this.$router.push('/');
      this.$cookies.remove('user_id');
      this.$cookies.remove('token');
      this.$cookies.remove('user_access');
      this.$cookies.remove('user_info');
      this.$store.commit('Auth/CHANGE_IS_AUTHENTICATED', false);
    },

    // Função para pegar a data atual e formatar pra mostrar em tela e pegar a primeira letra do nome
    getCurrentDate() {
      const currentDateDefault = new Date();
      const dateFormatted = format(currentDateDefault, "dd 'de' LLLL Y", {
        locale: ptBR,
      });

      this.currentDate = dateFormatted;

      const formatFirstLetterName = this.user.name.slice(0, 1);
      this.firstLetterName = formatFirstLetterName;
    },

    scrollToTop() {
      this.$refs.myDiv.scrollTop = 0;
    },
  },
};
</script>
<style>
.go-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #005aa0;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>
